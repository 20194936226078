.card {
  border: 1px solid #0000001f;
}


.base44-markdown {
    p, ul, ol {
        margin-bottom: 1em;
        line-height: 24px;
    }

    ul, ol {
        padding-left: 2em;
    }
    ul {
        list-style-type: disc;
    }
    ol {
        list-style-type: decimal;
    }
    li {
        margin-bottom: 0.5em;
    }

    pre, code {
        font-family: monospace;
        /* background-color: #f6f8fa; */
        border-radius: 3px;
    }
    pre {
        padding: 16px;
        overflow: auto;
        font-size: 85%;
        line-height: 1.45;
    }
    code {
        padding: 0.2em 0.4em;
        font-size: 85%;
    }

    /* Blockuotes */
    blockquote {
        margin: 0;
        padding-left: 1em;
        color: #6a737d;
        border-left: 0.25em solid #dfe2e5;
    }

    table {
        border-collapse: collapse;
        width: 100%;
        margin-bottom: 1em;
    }
    th, td {
        border: 1px solid #dfe2e5;
        padding: 6px 13px;
    }
    th {
        font-weight: 600;
        background-color: #f6f8fa;
    }

    hr {
        height: 0.25em;
        padding: 0;
        margin: 24px 0;
        background-color: #e1e4e8;
        border: 0;
    }

    
    img {
        max-width: 100%;
        height: auto;
    }
}

/* .base44-colors {
    .primary {
        color: #34d399;
    }
    .secondary {
        color: #fbbf24;
    }
    .accent {
        color: #059669;
    }
    .neutral {
        color: #213d48;
    }
    .base-100 {
        color: white;
    }
    .info {
        color: #0d9488;
    }
    .success {
        color: #4ade80;
    }
    .warning {
        color: #f59e0b;
    }
    .error {
        color: #b91c1c;
    }
} */


.app-sidebar {
    .\[\&_svg\]\:size-4 svg {
        width: 1.5rem;
        height: 1.5rem;
    }
}